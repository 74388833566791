import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import folderIcon from "./../../assets/folder_icon.svg";
import DashboardProjectList from "./DashboardProjectList";
import NewDocumentModal from "./NewDocumentModal";
import { getData } from "../../services/dataService";
import SpecialKeywords from "./SpecialKeywords";
import SpecialCharacters from "./SpecialCharacters";
import { getProfile } from "../../services/userService";
import { handleHttpError } from "../../services/httpErrorService";
import HumanizerMode from "./HumanizerMode";

export default function Dashboard() {
  const [handleLoaderOff] = useOutletContext();
  const [toggleState, setToggleState] = useState(1);
  const [tokenUser, setTokenUser] = useState([]);
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = getData("token");
    if (token != undefined || token != null) {
      getProfile().then((res) => {
        var userData = [res.data.result.user];
        setTokenUser(userData);
        setPackages(res.data.result.packages)
        console.log(res.data.result.packages);
      })
      .catch((err) => handleHttpError(err.response));
    } else {
      navigate("/login");
    }
  }, []);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [openCNDModal, setOpenCNDModal] = useState(false);

  function handleOpenCNDModal() {
    setOpenCNDModal(!openCNDModal);
  }

  return (
    <React.Fragment>
      <div className="container-fluid text-start">
        <div className="row">
          <div className="col-lg-12">
              {packages.length > 0 && packages[0].package_id == 19 && 
                <>
                  <div className="row">
                    {/* <div className="col-lg-5 d-flex align-items-center">
                      <div className="dash_head">
                        <h1>Hi {tokenUser && tokenUser[0]?.name}!</h1>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <hr /> */}
                    <HumanizerMode />
                  </div>
                </>
              }
              {packages.length > 0 && packages[0].package_id != 19 && 
                <>
                  <div className="row">
                    <div className="col-lg-5 d-flex align-items-center">
                      <div className="dash_head">
                        <h1>Hi {tokenUser && tokenUser[0]?.name}!</h1>
                      </div>
                    </div>
                    <div className="col text-end">
                      <span onClick={handleOpenCNDModal} className="theme-value-gray fw-medium cursor-pointer theme-btn7 my-2 d-inline-block">
                        <span className="me-1 mdi mdi-folder-plus-outline"></span>
                        &nbsp;&nbsp;Create New Book
                      </span>
                      {/* <ul className="dashTopOption">
                        <li>
                        </li>
                      </ul> */}
                    </div>
                    <div className="dashboardTab">
                      <ul className="nav nav-tabs">
                        <li className="nav-item cursor-pointer">
                          <span className={ toggleState === 1 ? "nav-link active" : "nav-link" } onClick={() => toggleTab(1)}>Recent Documents</span>
                        </li>
                        <li className="nav-item cursor-pointer">
                          <span className={ toggleState === 2 ? "nav-link active" : "nav-link"} onClick={() => toggleTab(2)}>All Documents</span>
                        </li>
                      </ul>
                      {tokenUser && tokenUser.length > 0 && 
                        <DashboardProjectList setOpenCNDModal={setOpenCNDModal} handleLoaderOff={handleLoaderOff} toggleState={toggleState} />
                      }
                    </div>
                  </div>
                </>
              }
          </div>
          <div className="col-lg-4">
            {/* <div className="card fs-12px custom-card mt-4">
              <div className="card-body">
                <h6 className="card-title fs-14px mb-4">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">Top Special Words</div>
                      <div className="col-6 text-end">
                        <span className="fw-normal" onClick={(e) => setSkwPopup(!skwPopup)}>
                          + Add more
                        </span>
                        {skwPopup && 
                          <SpecialKeywords skwPopup={skwPopup} setSkwPopup={setSkwPopup} />
                        }
                      </div>
                    </div>
                  </div>
                </h6>
                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col theme-label-gray">Keywords</div>
                    <div className="col text-end">
                      <span className="theme-value-gray">20</span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <a target="_blank" href="https://manuscripts.ai/how-to/special-words-report/" className="theme-link">
                    How to use special keywords
                    <span className="mdi mdi-alert-circle-outline ms-1"></span>
                  </a>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <h6 className="card-title fs-14px mb-4">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">Top Special Characters</div>
                      <div className="col-6 text-end">
                        <span className="fw-normal" onClick={(e) => setSkcPopup(!skcPopup)}>
                          + Add more
                        </span>
                        {skcPopup && 
                          <SpecialCharacters skcPopup={skcPopup} setSkcPopup={setSkcPopup} />
                        }
                      </div>
                    </div>
                  </div>
                </h6>
                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col theme-label-gray">Characters</div>
                    <div className="col text-end">
                      <span className="theme-value-gray">20</span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <a target="_blank" href="https://manuscripts.ai/how-to/special-character-report" className="theme-link">
                    How to use special characters
                    <span className="mdi mdi-alert-circle-outline ms-1"></span>
                  </a>
                </div>
              </div>
            </div> */}

            {/* <div className="col-12 dashboard_get_started_block mt-4 fs-12px">
              <div className="row">
                <h6 className="card-title fs-14px">Get Started</h6>
                <ul>
                  <li>
                    <a target="_blank" href="https://www.youtube.com/watch?v=6j1zlx3sSXg">
                      <span className="mdi mdi-play-circle-outline me-1"></span>
                      Watch tutorial video
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://manuscripts.ai/features/">
                      <span className="mdi mdi-abjad-hebrew me-1"></span>
                      Resource and guide
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:support@manuscripts.ai">
                      <span className="mdi mdi-email-check-outline me-1"></span>
                      Send us an email
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 dashboard_get_started_block mb-4 fs-12px">
              {tokenUser.length > 0 && tokenUser[0].user_mode == "Author" && 
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/XwNmSwINhfw" title="Getting Started With Manuscripts.ai For Authors" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              }
              {tokenUser.length > 0 && tokenUser[0].user_mode == "Student" && 
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/TbK37n1EOpA" title="Getting Started With Manuscripts.ai For Authors" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              }
            </div> */}
          </div>
        </div>
      </div>
      {tokenUser && 
        <NewDocumentModal
          modalToggle={openCNDModal}
          setOpenCNDModal={setOpenCNDModal}
          userData={tokenUser[0]}
        />
      }
    </React.Fragment>
  );
}
