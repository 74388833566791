import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.webp";
import MenuIcon from "../../assets/menu_icon.svg";
import { getData } from "../../services/dataService";
import { Link } from "react-router-dom";
import languages from "../../utils/languages";
import { getProfile, getUserLanguage } from "../../services/userService";
import { generateNotification } from "../../services/notificationService";

export default function TopHeader({
  page,
  toggleSidebar,
  sidebarToggle,
  togglePopup,
  selectedTokens,
  availableTokens,
  historyPopup,
  aiHistoryPopup,
  importFile,
  exportFile,
  exportFilePdf,
  bookFlow,
  setBookFlow,
  selectedLang,
  setSelectedLang,
  toggleHistoryPopup,
  toggleArpPopup,
  generateContent,
  docId,
  start,
  end, 
  userData,
  displayShortcuts,
  setSkipStepFlow
}) {

  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [toggleDropdownMenu, setToggleDropdownMenu] = useState(false);
  const [subMenuOption, setSubMenuOption] = useState('Reports');
  const [reportDetails, setReportDetails] = useState({ title: 'AI Editing', description: 'AI Edit Tool is an indispensable asset for writers and content creators looking to produce top-notch content. With its ability to enhance clarity, coherence, and overall impact, this tool is a must-have for anyone committed to delivering high-quality writing.', video: 'https://youtu.be/xcDr1q6LxjE', help: 'https://manuscripts.ai/ai-writer/' });
  const [reportDetails1, setReportDetails1] = useState({ title: 'AI Writing', description: 'These AI writing tools are revolutionizing the way content is created, providing writers with a powerful ally in their quest to produce high-quality, engaging content. By harnessing the power of AI, content creators can save time and energy while still delivering compelling and informative articles and blog posts.', video: 'https://youtu.be/3eQuBK1RPwg', help: 'https://manuscripts.ai/ai-writer/' });

  const triggerFile = (e) => {
    e.preventDefault();
    document.getElementById('myInput').click();
  }

  const updateLanguage = (language) => {
    getUserLanguage(language).then((res) => {
      setSelectedLang(language);
      setToggleSelect(false);
      setFilteredLanguages(languages);
      generateNotification({ title: "Account Settings Update", text: "Language settings updated successfully.", icon: "success" });
    }).catch((error) => {
      generateNotification({ title: "Account Settings Update", text: "Something went wrong while updating language settings.", icon: "error" });
    })
  }

  const [user,setUser] = useState([]);
  const [loadExport, setLoadExport] = useState(false);

  useEffect(() => {
    setUser(userData?.user);
    setFilteredLanguages(languages);
  }, []);

  const triggerExportFile = (e) => {
    setLoadExport(!loadExport);
    exportFile(e);
  }

  const triggerExportFilePdf = (e) => {
    setLoadExport(!loadExport);
    exportFilePdf(e);
  }

  const filterLanguages = (input) => {
    let filter = input.toUpperCase();
    let list = languages;
    let filteredList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].toUpperCase().indexOf(filter) > -1) {
        filteredList.push(list[i]);
      }
    }

    setFilteredLanguages(filteredList);
  }

  const loadSubMenu = (subMenuOption) => {
    setSubMenuOption(subMenuOption)
  }

  return (
      <div className="container-fluid custom_top_bar">
        <div className="row">
          <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light shadow-md">
              <div className="container-fluid">
                    <div className="me-2" style={{minWidth:'30px'}} onClick={() => toggleSidebar()}>
                      <img src={MenuIcon} className="img-fluid cursor-pointer" alt="" />
                    </div>
                  <a className="navbar-brand mx-2" href="/"><img src={logo} className="img-fluid" style={{ minWidth: '120px', maxWidth: '180px' }} alt="" /></a>
                  {page == "editor" &&
                    <button onClick={(e) => setToggleDropdownMenu(!toggleDropdownMenu)} className={`px-2 py-0 border d-lg-none d-block ${toggleDropdownMenu ? "theme-btn" : ""}`} type="button">
                        <span className="mdi mdi-dots-grid"></span>
                    </button>
                  }
                  <div className={`collapse navbar-collapse ${toggleDropdownMenu ? 'show' : ''}`} id="navbarSupportedContent">
                      {page == "editor" &&
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item me-1 px-2 dropdown desk-width-max-content">
                                <a className={`nav-link color-theme fs-12px dropdown-toggle me-1`} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  File
                                </a>
                                <ul className={`dropdown-menu bg-light`}>
                                    <li>
                                      <span onClick={(e) => triggerFile(e)} className="dropdown-item color-theme fs-14px cursor-pointer">Import</span>
                                      <input id="myInput" type="file" onChange={(e) => importFile(e)} style={{ display: "none" }} />
                                    </li>
                                    <li onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); triggerExportFile(e); }}><span className="dropdown-item color-theme fs-14px cursor-pointer">Export DOCx</span></li>
                                    <li onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); triggerExportFilePdf(e); }}><span className="dropdown-item color-theme fs-14px cursor-pointer">Export PDF</span></li>
                                </ul>
                            </li>
                            {/* <li className="nav-item me-1 px-2 dropdown desk-width-max-content">
                                <a className={`nav-link color-theme fs-12px dropdown-toggle me-1`} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  AI Editing
                                </a>
                                <div className={`dropdown-menu dropdown-mega-menu-custom p-0`}>
                                  <div className="row mx-0">
                                    <div className="col-md-5 bg-light text-start py-3 rounded-start">
                                      <ul className="list-unstyled">
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails({ title: 'Paraphrase', description: 'After selecting the desired prompts, the selected content will serve as the input for the AI system. The advanced algorithms will then work their magic, generating rephrased content that matches your chosen tone and style. The result? Your story will become even more immersive, captivating, and tailored to your artistic vision.', video: 'https://youtu.be/xcDr1q6LxjE', help: 'https://manuscripts.ai/paraphrasing-using-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Paraphrase'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Paraphrase</li>
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails({ title: 'AI Edit', description: 'Once the content has been selected for the AI Edit feature, users can expect a re-edit report that provides an in-depth analysis of the text. The AI Edit feature offers four distinct editing options to cater to different editing needs.', video: 'https://youtu.be/DqFs_PsOWJ0', help: 'https://manuscripts.ai/ai-edit-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'AI Edit'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">AI Edit</li>
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails({ title: 'Content Improver', description: 'Improve your content quickly and easily with Content Improver. Access it within your story book and select the content you want to rectify. Content Improver’s Artificial Intelligence will generate the content you need in no time! Try it now!', video: 'https://youtu.be/Yk6ifDeX2Rc', help: 'https://manuscripts.ai/content-improver-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Content Improver'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Content Improver</li>
                                        {userData?.user?.user_mode == "Author" && 
                                          <>
                                            <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails({ title: 'Voice Update', description: 'After selecting the desired prompts, the selected content will serve as the input for the AI system. The advanced algorithms will then work their magic, generating rephrased content that matches your chosen tone and style. The result? Your story will become even more immersive, captivating, and tailored to your artistic vision.', video: 'https://youtu.be/xcDr1q6LxjE', help: 'https://manuscripts.ai/ai-writer/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Voice Update'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Voice Update</li>
                                            <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails({ title: 'Perspective', description: 'The AI Perspective feature is a revolutionary tool for writers, allowing them to convert their content into First, Second, or Third Person perspectives with the help of Artificial Intelligence. This feature eliminates the need for manual tone and grammar correction, giving writers the opportunity to experiment with different perspectives without the tedious labor of changing the content manually.', video: 'https://youtu.be/0vNmOOpZVvg', help: 'https://manuscripts.ai/first-person-perspective-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Perspective'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Perspective</li>
                                          </>
                                        }
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails({ title: 'Improve Structure', description: 'Whether you’re a seasoned author looking to tighten the structure of a novel or a student striving for a well-organized essay, the Improve Structure feature will be your invaluable companion. It saves you time and effort by automatically generating enhanced content, granting you the freedom to focus on other aspects of your writing.', video: 'https://youtu.be/G5tnc3D0SmY', help: 'https://manuscripts.ai/improve-structure-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Improve Structure'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Improve Structure</li>
                                      </ul>
                                    </div>
                                    <div className="col-md-7 text-start p-4 rounded-end my-auto">
                                      <h5 className="fs-14px">{reportDetails.title}</h5>
                                      <p className="d-block text-wrap fs-12px fw-light">{reportDetails.description}</p>
                                      <p className="fs-10px">
                                        <a className="btn theme-btn6 fs-10px px-3 py-1 me-2" target="_blank" href={reportDetails.help}><span className="mdi mdi-information me-2"></span>How To</a>
                                        <a className="btn theme-btn5 fs-10px px-3 py-1" target="_blank" href={reportDetails.video}><span className="mdi mdi-youtube me-2"></span>Watch Video</a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                            </li>
                            <li className="nav-item me-1 px-2 dropdown desk-width-max-content">
                                <a className={`nav-link color-theme fs-12px dropdown-toggle me-1`} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  AI Writing
                                </a>
                                <div className={`dropdown-menu dropdown-mega-menu-custom p-0`}>
                                  <div className="row mx-0">
                                    <div className="col-md-5 bg-light text-start py-3 rounded-start">
                                      <ul className="list-unstyled">
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails1({ title: 'AI Rewrite', description: 'Experience AI Rewrite, the revolutionary content rewriting tool that lets you fine-tune your storybook content with ease! Just select the content you want to fine tune and AI Rewrite will use it as input to rephrase your story and remove any grammatical errors. Get perfect, error-free content today!', video: 'https://youtu.be/3eQuBK1RPwg', help: 'https://manuscripts.ai/rewrite-story-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'AI Rewrite'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">AI Rewrite</li>
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails1({ title: 'Expand Content with AI', description: 'Unlock endless possibilities with our AI Expand Content tool! Create a unique story book experience with your own content. Select the content you want to expand and let our AI do the rest – generating an extended story with ease. Try it now!', video: 'https://youtu.be/Sl3CcPuU818', help: 'https://manuscripts.ai/expand-story-using-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Expand Content with AI'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Expand Content with AI</li>
                                        {userData?.user?.user_mode == "Author" && 
                                          <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails1({ title: 'Complete Story with AI', description: 'Complete your story like a pro with AI! Get complete story tool – an AI powered tool that provides you with the perfect ending to your story. Access the tool within your story book and select the story content to get an AI generated ending that will leave your readers awestruck!', video: 'https://youtu.be/wyHFmlblzLY', help: 'https://manuscripts.ai/complete-story-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Complete Story with AI'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Complete Story with AI</li>
                                        }
                                        {userData?.user?.user_mode == "Student" && 
                                          <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails1({ title: 'Opposing Arguments', description: 'Complete your story like a pro with AI! Get complete story tool – an AI powered tool that provides you with the perfect ending to your story. Access the tool within your story book and select the story content to get an AI generated ending that will leave your readers awestruck!', video: 'https://youtu.be/wyHFmlblzLY', help: 'https://manuscripts.ai/complete-story-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Opposing Arguments'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Opposing Arguments</li>
                                        }
                                        <li data-title-navmenu="Click here to run the report." onMouseEnter={(e) => setReportDetails1({ title: 'Summary Generator', description: 'Create accurate summaries of any content with our Summary Generator! Simply select the content you’d like to summarize, and our AI will do the rest. Our tool is fast, accurate, and easy to use within your story book. Try it today and get the most out of your content.', video: 'https://youtu.be/2ynpiIieH9s', help: 'https://manuscripts.ai/summary-generator-selection-tools/' })} onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); generateContent(e, 'Summary Generator'); }} className="fs-14px mb-2 px-2 dropdown-item cursor-pointer">Summary Generator</li>
                                      </ul>
                                    </div>
                                    <div className="col-md-7 text-start p-4 rounded-end my-auto">
                                      <h5 className="fs-14px">{reportDetails1.title}</h5>
                                      <p className="d-block text-wrap fs-12px fw-light">{reportDetails1.description}</p>
                                      <p className="fs-10px">
                                        <a className="btn theme-btn6 fs-10px px-3 py-1 me-2" target="_blank" href={reportDetails1.help}><span className="mdi mdi-information me-2"></span>How To</a>
                                        <a className="btn theme-btn5 fs-10px px-3 py-1" target="_blank" href={reportDetails1.video}><span className="mdi mdi-youtube me-2"></span>Watch Video</a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                            </li> */}
                            {(userData?.user?.user_mode == "Author" || (userData?.user?.user_mode == "Student" && userData?.user?.email == 'briandrinkwine@gmail.com')) && (
                              <li onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); toggleArpPopup(); }} className="nav-item me-2 px-2 cursor-pointer desk-width-max-content">
                                  <span className="nav-link color-theme fs-12px">Reports</span>
                              </li>

                            )}
                            {userData?.user?.user_mode == "Author" && (
                              <>
                                <li className="nav-item me-2 px-2 cursor-pointer desk-width-max-content">
                                    <a className="text-decoration-none" target="_blank" href={`/ai-editor`}><span className="nav-link color-theme fs-12px">AI Templates</span></a>
                                </li>
                                <li onClick={(e) => { setToggleDropdownMenu(!toggleDropdownMenu); setSkipStepFlow(1); setBookFlow(true); }} className="nav-item me-2 px-2 cursor-pointer desk-width-max-content">
                                    <span className="nav-link color-theme fs-12px">Lore Book</span>
                                </li>
                              </>
                            )}
                            <li onClick={(e) => historyPopup()} className="d-lg-none d-block">
                              <span className="fs-12px"> <span className="mdi mdi-history"></span> History </span>
                            </li>
                            <li className="d-lg-none d-block">
                              <span className="nav-link color-theme fs-12px">Available tokens : <span className="fw-semibold">{availableTokens && availableTokens}</span></span>
                            </li>
                        </ul>
                      }
                      {page == "editor" &&
                        <>
                          <div 
                            onClick={(e) => setToggleSelect(!toggleSelect)} 
                            className="light_gray_btn fs-12px me-2 cursor-pointer text-truncate"
                          >
                            {selectedLang ? selectedLang : 'Select Language'} <i className="ms-1 mdi mdi-menu-down"></i>
                            <div className="position-absolute" style={{ marginTop: "10px", marginLeft: "-3rem" }}>
                              <div className={`dropdown ${toggleSelect ? 'show' : ''} bg-light text-start px-3`}>
                                <div className={`dropdown-menu ${toggleSelect ? 'show' : ''}`} style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                  <input className="form-control fs-12px border-0 border-bottom" type="text" placeholder="Search Languages.." onKeyUp={(e) => filterLanguages(e.target.value)} />
                                  {filteredLanguages && filteredLanguages.map((language, i) => (
                                    <div key={i} style={{ lineHeight: '30px' }} onClick={(e) => updateLanguage(language)} className={`dropdown-item cursor-pointer fs-12px ${selectedLang == language ? 'active' : ''}`}>{language}</div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <button data-title-bottom="Shortcuts" className="border-0 light_orange_btn fs-14px me-2 d-none d-lg-block px-3" onClick={(e) => displayShortcuts(true)}><span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="History" className="mdi mdi-snowflake"></span></button>
                          <button data-title-bottom="History" className="border-0 light_orange_btn fs-14px me-2 d-none d-lg-block px-3" onClick={(e) => historyPopup()}><span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="History" className="mdi mdi-history"></span></button>
                          <span className="navbar-text mx-1 p-0 d-none d-lg-block">
                            <span className="light_gray_btn fs-12px desk-width-max-content" style={{display:"block"}}>Available tokens : {availableTokens && availableTokens}</span>
                          </span>
                        </>
                      }
                  </div>
              </div>
          </nav>
        </div>
      </div>
  );
}
