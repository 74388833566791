import { Link } from "react-router-dom";

import TextReadabilityIcon from "../../assets/addi-report/text-readability.png"
import DialogueTagIcon from "../../assets/addi-report/dialogue-tag.png"
import SentenceIcon from "../../assets/addi-report/sentence.png"
import ShowTellIcon from "../../assets/addi-report/show-tell.png"
import StickyIcon from "../../assets/addi-report/sticky.png"
import RedundanciesIcon from "../../assets/addi-report/redundancies.png"
import SentimentIcon from "../../assets/addi-report/sentiment.png"
import PacingIcon from "../../assets/addi-report/reading-pace.png"
import { useState } from "react";


export default function AdditionalReportsPanelMobile ({ docId }) {

    const [showReportList, setShowReportList] = useState(false);

    return (
        <>
            <div onClick={(e) => setShowReportList(!showReportList)} className="position-fixed reportIcon shadow-sm">
                {!showReportList && <span className="text-white mdi mdi-poll"></span>}
                {showReportList && <span className="text-white mdi mdi-close"></span>}
            </div>
            {showReportList && 
                <div className="position-fixed report-list">
                    <Link className="text-decoration-none color-theme" to={`/editor/text-readability?doc=${docId}`}>
                        <img className="img-fluid" src={TextReadabilityIcon} alt="Text Readability Icon" />
                        <span>Readability</span>
                    </Link>
                    <hr />
                    <Link className="text-decoration-none color-theme" to={`/editor/dialogue-tag?doc=${docId}`}>
                        <img className="img-fluid" src={DialogueTagIcon} alt="Dialogue Tag Icon" />
                        <span>Dilogue Tag</span>
                    </Link>
                    <hr />
                    <Link className="text-decoration-none color-theme" to={`/editor/sentence-variation?doc=${docId}`}>
                        <img className="img-fluid" src={SentenceIcon} alt="Sentence Variation Icon" />
                        <span>Sentence Variation</span>
                    </Link>
                    <hr />
                    <Link className="text-decoration-none color-theme" to={`/editor/showing-telling?doc=${docId}`}>
                        <img className="img-fluid" src={ShowTellIcon} alt="Show Tell Icon" />
                        <span>Show Tell</span>
                    </Link>
                    <hr />
                    <Link className="text-decoration-none color-theme" to={`/editor/sticky-sentences?doc=${docId}`}>
                        <img className="img-fluid" src={StickyIcon} alt="Sticky Icon" />
                        <span>Sticky</span>
                    </Link>
                    <hr />
                    <Link className="text-decoration-none color-theme" to={`/editor/redundancies?doc=${docId}`}>
                        <img className="img-fluid" src={RedundanciesIcon} alt="Redundancies Icon" />
                        <span>Redundancies</span>
                    </Link>
                    <hr />
                    <Link className="text-decoration-none color-theme" to={`/editor/reading-pace?doc=${docId}`}>
                        <img className="img-fluid" src={PacingIcon} alt="Pacing Icon" />
                        <span>Pacing</span>
                    </Link>
                </div>
            }
        </>
    )
}